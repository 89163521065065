/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        common: {
            init: function() {
                $(".video-container .play-video").on("click", function(e) {
                    $poster = $(this).parent();
                    $poster.addClass("playing-video");

                    $iframe = $poster.next();
                    $iframe[0].src += "&autoplay=1";
                    e.preventDefault();
                });

                /**
                 *  Add shrink class to header on scroll
                 */

                var $body = $("body"),
                    $threshold = 100,
                    $window = $(window);

                $window.scroll(function() {
                    $scroll_position = $window.scrollTop();
                    if ($scroll_position > $threshold) {
                        $body.addClass("shrink");
                    } else {
                        $body.removeClass("shrink");
                    }
                });

                /**
                 *  Mobile Menu trigger
                 */

                $(".menu-trigger").on("click touchstart", function(e) {
                    e.preventDefault();
                    if ($body.hasClass("menu-open")) {
                        $body.removeClass("menu-open");
                    } else {
                        $body.addClass("menu-open");
                    }
                });

                /**
                 *  scroll to section for btn
                 */
                $("a[href^='/#']").click(function(e) {
                    e.preventDefault();
                    href = $(this)
                        .attr("href")
                        .substr(1);
                    $("html, body").animate(
                        {
                            scrollTop: $(href).offset().top - 66
                        },
                        1000
                    );
                });

                /**
                 *  Enable/disable quote form input based on validation
                 */

                if ($("#quote-name-input").val()) {
                    $("#quote-form-submit").prop("disabled", false);
                }

                $("#quote-name-input").on("change paste keyup", function() {
                    console.log("test");
                    if ($(this).val()) {
                        $("#quote-form-submit").prop("disabled", false);
                    } else {
                        $("#quote-form-submit").prop("disabled", true);
                    }
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        home: {
            init: function() {
                /**
                 *  Scroll Magic stuff
                 */

                // init controller
                var controller = new ScrollMagic.Controller();

                // define Tweens
                var homeBannerCloudTween = TweenMax.to(
                    ".block.home-banner .container .cloud",
                    1,
                    {
                        x: "50%"
                    }
                );
                var quoteNameCloudTween = TweenMax.to(
                    ".block.quote-name .container .cloud",
                    1,
                    {
                        x: "-50%"
                    }
                );
                var quoteNameSunTween = TweenMax.to(
                    ".block.quote-name .container .sunset .sun",
                    1,
                    {
                        y: "60%"
                    }
                );
                var termsStoneTween = TweenMax.to(
                    ".block.terms .container .tombstone .stone",
                    1,
                    {
                        y: "-90%"
                    }
                );

                // Setup scenes
                var homeBannerCloudScene = new ScrollMagic.Scene({
                    duration: 1000 /* How many pixels to scroll / animate */
                }).setTween(homeBannerCloudTween);

                var quoteNameCloudScene = new ScrollMagic.Scene({
                    duration: 1000 /* How many pixels to scroll / animate */
                }).setTween(quoteNameCloudTween);

                var quoteNameSunScene = new ScrollMagic.Scene({
                    triggerElement: ".block.quote-name .security",
                    offset: -75,
                    duration: 500 /* How many pixels to scroll / animate */
                }).setTween(quoteNameSunTween);

                var termsStoneScene = new ScrollMagic.Scene({
                    triggerElement: ".block.terms .all-plans-link",
                    offset: -250,
                    duration: 500 /* How many pixels to scroll / animate */
                }).setTween(termsStoneTween);

                // Add Scenes to ScrollMagic Controller
                controller.addScene([
                    homeBannerCloudScene,
                    quoteNameCloudScene,
                    quoteNameSunScene,
                    termsStoneScene
                ]);
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        slider_block: {
            init: function() {
                /**
                 *  Slick Slider
                 */
                var slider = $(".slider-block-slick"),
                    autoplay = slider.data('autoplay'),
                    autoplaySpeed = slider.data('autoplayspeed');

                slider.slick({
                    dots: true,
                    adaptiveHeight: true,
                    autoplay: autoplay,
                    autoplaySpeed: autoplaySpeed
                });
            }
        },
        faq_block: {
            init: function() {
                /**
                 *  Accordion functionality
                 */

                var acc = document.getElementsByClassName("question");
                var i;

                for (i = 0; i < acc.length; i++) {
                    acc[i].onclick = function(e) {
                        e.preventDefault();

                        this.classList.toggle("active");
                        var panel = this.nextElementSibling;

                        if (panel.style.maxHeight) {
                            panel.style.maxHeight = null;
                        } else {
                            panel.style.maxHeight = panel.scrollHeight + "px";
                        }
                    };
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function(i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
